/* .section-shaped .shape-default {
  background: url(assets/img/hero-book-render.jpg) no-repeat;
  background-position: 25% 50%;
  background-size: cover;
} */

.section-shaped .shape-style-1.shape-default {
  background: linear-gradient(150deg, #91a4d5 15%, #a5a9b2 70%, #4b5e8c 94%);
}

.nav-item {
  align-items: center;
  display: flex;
}